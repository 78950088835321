import React from 'react';
import { X, Facebook, Twitter, Link, Share2 } from 'lucide-react';
import toast from 'react-hot-toast';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  dealerId: string;
  dealerName: string;
}

export default function ShareModal({ isOpen, onClose, dealerId, dealerName }: ShareModalProps) {
  const shareUrl = `${window.location.origin}/dealer-profile/${dealerId}`;
  const shareText = `Check out ${dealerName}'s profile on HereDeal!`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Link copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy link:', error);
      toast.error('Failed to copy link');
    }
  };

  const handleShare = (platform: string) => {
    const urls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(`${shareText} ${shareUrl}`)}`,
    };

    const url = urls[platform as keyof typeof urls];
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center">
          <Share2 className="w-12 h-12 mx-auto text-indigo-600 mb-4" />
          <h2 className="text-2xl font-bold mb-6">Share Profile</h2>
        </div>

        <div className="space-y-4">
          <button
            onClick={() => handleShare('facebook')}
            className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Facebook className="w-5 h-5" />
            Share on Facebook
          </button>

          <button
            onClick={() => handleShare('twitter')}
            className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-sky-500 text-white rounded-lg hover:bg-sky-600 transition-colors"
          >
            <Twitter className="w-5 h-5" />
            Share on Twitter
          </button>

          <button
            onClick={handleCopyLink}
            className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors"
          >
            <Link className="w-5 h-5" />
            Copy Profile Link
          </button>
        </div>
      </div>
    </div>
  );
}