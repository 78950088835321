import React from 'react';
import { UseFormRegister, FormState, UseFormWatch } from 'react-hook-form';
import { ItemFormData } from '../../../utils/validation';

interface ContactDetailsProps {
  register: UseFormRegister<ItemFormData>;
  errors: FormState<ItemFormData>['errors'];
  watch: UseFormWatch<ItemFormData>;
  isSubmitting: boolean;
}

export default function ContactDetails({
  register,
  errors,
  watch,
  isSubmitting
}: ContactDetailsProps) {
  const contactPreference = watch('contactPreference');

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Contact Preference
        </label>
        <select
          {...register('contactPreference')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          <option value="message">Message Only</option>
          <option value="phone">Phone Only</option>
          <option value="email">Email Only</option>
          <option value="both">Both Message and Phone</option>
        </select>
      </div>

      {(contactPreference === 'phone' || contactPreference === 'both') && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            {...register('phoneNumber')}
            disabled={isSubmitting}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            placeholder="Enter your phone number"
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-sm mt-1">{errors.phoneNumber.message}</p>
          )}
        </div>
      )}

      {(contactPreference === 'email' || contactPreference === 'both') && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email Address
          </label>
          <input
            type="email"
            {...register('email')}
            disabled={isSubmitting}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            placeholder="Enter your email address"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>
      )}
    </div>
  );
}