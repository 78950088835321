import React, { useState, useEffect } from 'react';
import { Plus, Store } from 'lucide-react';
import { Navigate, useNavigate } from 'react-router-dom';
import AddItemModal from '../components/UserProfile/AddItemModal';
import UserItems from '../components/UserProfile/UserItems';
import { useItems } from '../hooks/useItems';
import { useAuth } from '../hooks/useAuth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

export default function Profile() {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [userType, setUserType] = useState<'dealer' | 'owner' | null>(null);
  const { user, loading: authLoading } = useAuth();
  const { items, loading: itemsLoading, error, addItem, deleteItem, updateItem, updateItemStatus } = useItems(user?.uid);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserType = async () => {
      if (!user) return;
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserType(userDoc.data().userType);
        }
      } catch (error) {
        console.error('Error fetching user type:', error);
      }
    };

    fetchUserType();
  }, [user]);

  if (authLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  const handleAddItem = async (data: any) => {
    try {
      const id = await addItem(data);
      if (id) {
        setIsAddModalOpen(false);
        toast.success('Item added successfully!');
      }
    } catch (error: any) {
      console.error('Error adding item:', error);
      toast.error(error.message || 'Failed to add item');
    }
  };

  const handleDeleteItem = async (id: string) => {
    try {
      await deleteItem(id);
    } catch (error: any) {
      console.error('Error deleting item:', error);
      toast.error(error.message || 'Failed to delete item');
    }
  };

  const handleUpdateItem = async (id: string, data: any) => {
    try {
      await updateItem(id, data);
    } catch (error: any) {
      console.error('Error updating item:', error);
      toast.error(error.message || 'Failed to update item');
    }
  };

  const handleUpdateStatus = async (id: string, status: 'available' | 'sold') => {
    try {
      await updateItemStatus(id, status);
    } catch (error: any) {
      console.error('Error updating item status:', error);
      toast.error(error.message || 'Failed to update item status');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {error && (
          <div className="mb-6 bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
            {error}
          </div>
        )}

        <div className="mb-8">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">My Items</h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your listed items here
              </p>
            </div>
            <div className="flex gap-4">
              {userType === 'dealer' && (
                <button
                  onClick={() => navigate(`/dealer-profile/${user.uid}`)}
                  className="flex items-center gap-2 bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  <Store className="w-5 h-5" />
                  Dealer Profile
                </button>
              )}
              <button
                onClick={() => setIsAddModalOpen(true)}
                className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                <Plus className="w-5 h-5" />
                Add New Item
              </button>
            </div>
          </div>
        </div>

        {itemsLoading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
          </div>
        ) : items.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <Plus className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No items</h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by adding your first item
            </p>
            <div className="mt-6">
              <button
                onClick={() => setIsAddModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <Plus className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Add New Item
              </button>
            </div>
          </div>
        ) : (
          <UserItems
            items={items}
            onDelete={handleDeleteItem}
            onUpdateStatus={handleUpdateStatus}
            onUpdateItem={handleUpdateItem}
          />
        )}

        <AddItemModal
          isOpen={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onSubmit={handleAddItem}
        />
      </div>
    </div>
  );
}