import React, { useState } from 'react';
import { LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import SignUpModal from './SignUpModal';
import AuthModal from './AuthModal';
import toast from 'react-hot-toast';

export default function AuthButton() {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { user, signOut, showSignUp, setShowSignUp, completeSignUp } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      toast.success('Signed out successfully');
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  return (
    <>
      <button
        onClick={user ? handleSignOut : () => setShowAuthModal(true)}
        className="flex items-center gap-2 px-4 py-2 rounded-lg bg-indigo-600 hover:bg-indigo-700 text-white transition-colors"
      >
        {user ? (
          <>
            <LogOut className="w-4 h-4" />
            Sign Out
          </>
        ) : (
          'Sign In'
        )}
      </button>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)} 
      />

      {user && showSignUp && (
        <SignUpModal
          isOpen={showSignUp}
          onClose={() => setShowSignUp(false)}
          onComplete={completeSignUp}
          userId={user.uid}
        />
      )}
    </>
  );
}