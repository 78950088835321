import React, { useState, useEffect, useRef } from 'react';
import { X, Send } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../lib/firebase';
import { collection, addDoc, query, where, orderBy, onSnapshot, serverTimestamp, getDocs, updateDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';

interface Message {
  id: string;
  text: string;
  senderId: string;
  createdAt: any;
}

interface ChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemId: string;
  sellerId: string;
  itemName: string;
  embedded?: boolean;
  chatId?: string;
}

export default function ChatModal({ isOpen, onClose, itemId, sellerId, itemName, embedded = false, chatId: existingChatId }: ChatModalProps) {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [chatId, setChatId] = useState(existingChatId || '');
  const [isMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isOpen || !user) return;

    const initializeChat = async () => {
      try {
        if (!existingChatId) {
          const chatsRef = collection(db, 'chats');
          const q = query(
            chatsRef,
            where('participants', 'array-contains', user.uid),
            where('itemId', '==', itemId)
          );

          const querySnapshot = await getDocs(q);
          const existingChat = querySnapshot.docs.find(doc => 
            doc.data().participants.includes(sellerId)
          );

          if (existingChat) {
            setChatId(existingChat.id);
          } else {
            const newChatRef = await addDoc(collection(db, 'chats'), {
              participants: [user.uid, sellerId],
              itemId,
              itemName,
              lastMessage: '',
              lastMessageAt: serverTimestamp(),
              unreadBy: {},
            });
            
            setChatId(newChatRef.id);
          }
        } else {
          setChatId(existingChatId);
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
        toast.error('Failed to initialize chat');
      }
    };

    initializeChat();
  }, [isOpen, user, itemId, sellerId, existingChatId]);

  useEffect(() => {
    if (!isOpen || !user || !chatId) return;

    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const messagesQuery = query(messagesRef, orderBy('createdAt', 'asc'));

    const markAsRead = async () => {
      try {
        const chatDocRef = doc(db, 'chats', chatId);
        const chatDoc = await getDoc(chatDocRef);
        
        if (chatDoc.exists()) {
          const currentUnreadBy = chatDoc.data().unreadBy || {};
          if (currentUnreadBy[user.uid]) {
            currentUnreadBy[user.uid] = 0;
            await updateDoc(chatDocRef, { unreadBy: currentUnreadBy });
          }
        }
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    };

    markAsRead();

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Message[];
      setMessages(newMessages);
      
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    });

    return () => unsubscribe();
  }, [isOpen, user, chatId]);

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user || !chatId) return;

    setIsLoading(true);
    try {
      const messageData = {
        text: message.trim(),
        senderId: user.uid,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'chats', chatId, 'messages'), messageData);

      const chatDocRef = doc(db, 'chats', chatId);
      const chatDoc = await getDoc(chatDocRef);
      
      if (chatDoc.exists()) {
        const currentUnreadBy = chatDoc.data().unreadBy || {};
        const otherParticipant = chatDoc.data().participants.find((p: string) => p !== user.uid);
        if (otherParticipant) {
          currentUnreadBy[otherParticipant] = (currentUnreadBy[otherParticipant] || 0) + 1;
        }

        await updateDoc(chatDocRef, {
          lastMessage: message.trim(),
          lastMessageAt: serverTimestamp(),
          unreadBy: currentUnreadBy,
        });
      }

      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen || !user) return null;

  const modalContent = (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b flex justify-between items-center">
        <h3 className="text-lg font-semibold line-clamp-1">{itemName}</h3>
        {!embedded && (
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        )}
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.length === 0 ? (
          <div className="text-center text-gray-500 py-4">
            No messages yet. Start the conversation!
          </div>
        ) : (
          messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex ${msg.senderId === user.uid ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[80%] rounded-lg px-4 py-2 ${
                  msg.senderId === user.uid
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-900'
                }`}
              >
                <p className="break-words">{msg.text}</p>
                <span className="text-xs opacity-75 mt-1 block">
                  {msg.createdAt?.toDate?.().toLocaleTimeString()}
                </span>
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={sendMessage} className="p-4 border-t">
        <div className="flex gap-2">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !message.trim()}
            className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </form>
    </div>
  );

  if (embedded) {
    return modalContent;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`bg-white rounded-lg w-full ${isMobile ? 'h-full' : 'max-w-md h-[600px]'}`}>
        {modalContent}
      </div>
    </div>
  );
}