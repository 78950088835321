import { Item } from '../types/item';
import { FilterOptions } from '../types/filters';

export const filterItems = (items: Item[], filters: FilterOptions): Item[] => {
  return items.filter(item => {
    // Price Range Filter
    if (filters.priceRange) {
      const { min, max } = filters.priceRange;
      if (min && item.price < min) return false;
      if (max && item.price > max) return false;
    }

    // Location Filter
    if (filters.location) {
      const { state, zipCode } = filters.location;
      if (state && item.location?.state !== state) return false;
      if (zipCode && item.location?.zipCode !== zipCode) return false;
    }

    // Seller Type Filter
    if (filters.sellerType && filters.sellerType !== 'all') {
      if (item.userType !== filters.sellerType) return false;
    }

    // Condition Filter
    if (filters.condition && filters.condition !== 'all') {
      if (item.condition !== filters.condition) return false;
    }

    // Category Filter
    if (filters.category && filters.category !== 'all') {
      if (item.category !== filters.category) return false;
    }

    // Community Filter
    if (filters.community && filters.community !== 'all') {
      if (item.community !== filters.community) return false;
    }

    return true;
  }).sort((a, b) => {
    if (!filters.sortBy || filters.sortBy === 'newest') {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }
    if (filters.sortBy === 'price-low') {
      return a.price - b.price;
    }
    if (filters.sortBy === 'price-high') {
      return b.price - a.price;
    }
    return 0;
  });
};