import React, { useState, useRef, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Item } from '../../types/item';
import { getSearchSuggestions } from '../../utils/search';
import { useDebounce } from '../../hooks/useDebounce';
import { formatPrice } from '../../utils/format';

interface SearchBarProps {
  items: Item[];
  onSearch: (term: string) => void;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  initialTerm?: string;
}

export default function SearchBar({
  items,
  onSearch,
  className = '',
  placeholder = 'Search products...',
  autoFocus = false,
  initialTerm = ''
}: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState(initialTerm);
  const [suggestions, setSuggestions] = useState<Item[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const newSuggestions = getSearchSuggestions(items, debouncedSearchTerm);
      setSuggestions(newSuggestions);
      onSearch(debouncedSearchTerm);
    } else {
      setSuggestions([]);
      onSearch('');
    }
  }, [debouncedSearchTerm, items, onSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (item: Item) => {
    setSearchTerm(item.name);
    setShowSuggestions(false);
    onSearch(item.name);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
    onSearch('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowSuggestions(false);
    onSearch(searchTerm);
  };

  return (
    <div ref={searchRef} className={`relative ${className}`}>
      <form onSubmit={handleSubmit} className="relative">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setShowSuggestions(true)}
          placeholder={placeholder}
          className="w-full bg-gray-100 rounded-full py-2 pl-10 pr-10 focus:outline-none focus:ring-2 focus:ring-indigo-600"
          autoFocus={autoFocus}
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        {searchTerm && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </form>

      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
          {suggestions.map((item) => (
            <button
              key={item.id}
              onClick={() => handleSuggestionClick(item)}
              className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center gap-3"
            >
              <img
                src={item.images?.[0] || 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?w=800&auto=format&fit=crop&q=60'}
                alt={item.name}
                className="w-10 h-10 object-cover rounded"
              />
              <div>
                <div className="font-medium">{item.name}</div>
                <div className="text-sm text-gray-500">
                  {formatPrice(item.price)}
                  {item.dealerName && (
                    <span className="ml-2 text-gray-400">by {item.dealerName}</span>
                  )}
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}