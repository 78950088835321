import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { Item } from '../../hooks/useItems';

const itemSchema = z.object({
  price: z.number().min(0, 'Price must be 0 or greater'),
  status: z.enum(['available', 'sold']),
});

type EditFormData = z.infer<typeof itemSchema>;

interface EditItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EditFormData) => Promise<void>;
  item: Item;
}

export default function EditItemModal({ isOpen, onClose, onSubmit, item }: EditItemModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditFormData>({
    resolver: zodResolver(itemSchema),
    defaultValues: {
      price: item.price || 0,
      status: item.status || 'available',
    },
  });

  const onSubmitForm = async (data: EditFormData) => {
    try {
      setIsSubmitting(true);
      await onSubmit(data);
      onClose();
    } catch (error: any) {
      console.error('Error submitting form:', error);
      toast.error(error.message || 'Failed to update item');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          disabled={isSubmitting}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 disabled:opacity-50"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">Edit Item: {item.name}</h2>

        <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Price ($)
            </label>
            <input
              type="number"
              step="0.01"
              min="0"
              {...register('price', { valueAsNumber: true })}
              disabled={isSubmitting}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
              placeholder="Enter price (0 for free items)"
            />
            {errors.price && (
              <p className="text-red-500 text-sm mt-1">{errors.price.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              {...register('status')}
              disabled={isSubmitting}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              <option value="available">Available</option>
              <option value="sold">Sold</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Updating...' : 'Update Item'}
          </button>
        </form>
      </div>
    </div>
  );
}