import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  addDoc,
  deleteDoc, 
  doc, 
  where,
  orderBy,
  onSnapshot,
  limit,
  serverTimestamp,
  updateDoc,
  getDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, auth, storage } from '../lib/firebase';
import toast from 'react-hot-toast';
import { Item } from '../types/item';

export function useItems(userId?: string, includeAll: boolean = true) {
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!db) {
      setError('Firebase is not initialized');
      setLoading(false);
      return;
    }

    let unsubscribe: () => void;

    const fetchItems = async () => {
      try {
        const itemsCollection = collection(db, 'items');
        let baseQuery;

        if (userId) {
          baseQuery = query(
            itemsCollection,
            where('userId', '==', userId),
            orderBy('createdAt', 'desc'),
            limit(50)
          );
        } else {
          baseQuery = query(
            itemsCollection,
            orderBy('createdAt', 'desc'),
            limit(50)
          );
        }

        unsubscribe = onSnapshot(
          baseQuery,
          async (snapshot) => {
            try {
              const itemsPromises = snapshot.docs.map(async (docSnapshot) => {
                const itemData = docSnapshot.data();
                const userDocRef = doc(db, 'users', itemData.userId);
                const userDocSnap = await getDoc(userDocRef);
                const userData = userDocSnap.data();
                
                return {
                  id: docSnapshot.id,
                  ...itemData,
                  userType: userData?.userType || 'owner',
                  dealerName: userData?.displayName,
                  uniqueKey: `${docSnapshot.id}-${Date.now()}`,
                  createdAt: itemData.createdAt?.toDate?.() || new Date(),
                  status: itemData.status || 'available',
                  images: itemData.images || []
                } as Item;
              });

              const fetchedItems = await Promise.all(itemsPromises);
              setItems(includeAll ? fetchedItems : fetchedItems.filter(item => item.status !== 'sold'));
              setLoading(false);
              setError(null);
            } catch (err) {
              console.error('Error processing items:', err);
              setError('Failed to process items');
              setLoading(false);
            }
          },
          (err) => {
            console.error('Error fetching items:', err);
            if (!isOnline) {
              setError('You are currently offline. Some features may be limited.');
            } else {
              setError('Failed to load items. Please try again.');
            }
            setLoading(false);
          }
        );
      } catch (err) {
        console.error('Error setting up items query:', err);
        setError('Failed to load items. Please try again.');
        setLoading(false);
      }
    };

    fetchItems();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userId, includeAll, isOnline]);

  const addItem = async (data: any) => {
    if (!auth.currentUser) {
      throw new Error('Please sign in to add items');
    }

    if (!isOnline) {
      throw new Error('You are offline. Please check your internet connection.');
    }

    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userType = userDocSnap.data()?.userType || 'owner';

      const timestamp = Date.now();
      const itemData = {
        ...data,
        userId: auth.currentUser.uid,
        userType,
        createdAt: serverTimestamp(),
        uniqueKey: `new-item-${timestamp}`,
        status: 'available'
      };

      const docRef = await addDoc(collection(db, 'items'), itemData);
      await updateDoc(docRef, {
        uniqueKey: `${docRef.id}-${timestamp}`
      });

      toast.success('Item added successfully!');
      return docRef.id;
    } catch (error: any) {
      console.error('Error adding item:', error);
      throw new Error(error.message || 'Failed to add item');
    }
  };

  const deleteItem = async (id: string) => {
    if (!auth.currentUser) {
      throw new Error('Please sign in to delete items');
    }

    if (!isOnline) {
      throw new Error('You are offline. Please check your internet connection.');
    }

    try {
      const item = items.find(item => item.id === id);
      if (item?.images?.length > 0) {
        const deletePromises = item.images.map(async (url) => {
          if (!url.includes('unsplash.com')) {
            try {
              const imageRef = ref(storage, url);
              await deleteObject(imageRef);
            } catch (error) {
              console.error('Error deleting image:', error);
            }
          }
        });
        await Promise.all(deletePromises);
      }
      await deleteDoc(doc(db, 'items', id));
      toast.success('Item deleted successfully!');
    } catch (error: any) {
      console.error('Error deleting item:', error);
      throw new Error('Failed to delete item');
    }
  };

  const updateItemStatus = async (id: string, status: 'available' | 'sold') => {
    if (!auth.currentUser) {
      throw new Error('Please sign in to update items');
    }

    if (!isOnline) {
      throw new Error('You are offline. Please check your internet connection.');
    }

    try {
      await updateDoc(doc(db, 'items', id), { status });
      toast.success(`Item marked as ${status}`);
    } catch (error: any) {
      console.error('Error updating item status:', error);
      throw new Error('Failed to update item status');
    }
  };

  const updateItem = async (id: string, data: Partial<Item>) => {
    if (!auth.currentUser) {
      throw new Error('Please sign in to update items');
    }

    if (!isOnline) {
      throw new Error('You are offline. Please check your internet connection.');
    }

    try {
      await updateDoc(doc(db, 'items', id), data);
      toast.success('Item updated successfully!');
    } catch (error: any) {
      console.error('Error updating item:', error);
      throw new Error(error.message || 'Failed to update item');
    }
  };

  return {
    items,
    loading,
    error,
    isOnline,
    addItem,
    deleteItem,
    updateItemStatus,
    updateItem,
  };
}