import { toast } from 'react-hot-toast';

export interface LocationData {
  city: string;
  state: string;
}

export const getLocationFromCoordinates = async (
  latitude: number,
  longitude: number
): Promise<LocationData | null> => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );

    if (!response.ok) {
      toast.error('Failed to fetch location data');
      console.error('Error fetching location data:', response.statusText);
      return null;
    }

    const data = await response.json();

    const city = data.address.city || data.address.town || data.address.village;
    const state = data.address.state;

    if (city && state) {
      return { city, state };
    } else {
      toast.error('Location data incomplete');
      console.error('Incomplete location data:', data.address);
      return null;
    }
  } catch (error) {
    toast.error('Error getting location');
    console.error('Error getting location:', error);
    return null;
  }
};

export const getCurrentLocation = async (): Promise<LocationData | null> => {
  if (!navigator.geolocation) {
    toast.error('Geolocation is not supported by your browser');
    return null;
  }

  try {
    const position = await new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const location = await getLocationFromCoordinates(
      position.coords.latitude,
      position.coords.longitude
    );

    if (!location) {
      toast.error('Could not determine your location');
      return null;
    }

    return location;
  } catch (error) {
    toast.error('Failed to get your location');
    console.error('Geolocation error:', error);
    return null;
  }
};

export const US_STATES = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 
  'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 
  'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
  'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 
  'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 
  'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 
  'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
  'West Virginia', 'Wisconsin', 'Wyoming'
];