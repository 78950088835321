import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

export default function EmailVerificationBanner() {
  const { user, resendVerificationEmail } = useAuth();

  if (!user || user.emailVerified) return null;

  const handleResendClick = async () => {
    await resendVerificationEmail();
  };

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <AlertTriangle className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Please verify your email address to access all features.
            <button
              onClick={handleResendClick}
              className="ml-2 font-medium text-yellow-700 underline hover:text-yellow-600"
            >
              Resend verification email
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}