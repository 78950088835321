import { Item } from '../types/item';
import { FilterOptions } from '../types/filters';
import { filterItems } from './filters';

const WEIGHTS = {
  name: 1.0,
  description: 0.5,
  category: 0.8,
  dealerName: 0.7
};

export const calculateRelevanceScore = (item: Item, searchTerm: string): number => {
  const searchLower = searchTerm.toLowerCase();
  let score = 0;

  // Name matching
  if (item.name.toLowerCase().includes(searchLower)) {
    score += WEIGHTS.name;
    if (item.name.toLowerCase() === searchLower) {
      score += WEIGHTS.name * 0.5;
    } else if (item.name.toLowerCase().startsWith(searchLower)) {
      score += WEIGHTS.name * 0.3;
    }
  }

  // Description matching
  if (item.description?.toLowerCase().includes(searchLower)) {
    score += WEIGHTS.description;
  }

  // Category matching
  if (item.category?.toLowerCase().includes(searchLower)) {
    score += WEIGHTS.category;
  }

  // Dealer name matching
  if (item.dealerName?.toLowerCase().includes(searchLower)) {
    score += WEIGHTS.dealerName;
    if (item.dealerName.toLowerCase() === searchLower) {
      score += WEIGHTS.dealerName * 0.5;
    }
  }

  return score;
};

export const getSearchSuggestions = (
  items: Item[],
  searchTerm: string,
  maxSuggestions: number = 5
): Item[] => {
  if (!searchTerm) return [];

  return items
    .map(item => ({
      item,
      score: calculateRelevanceScore(item, searchTerm)
    }))
    .filter(({ score }) => score > 0)
    .sort((a, b) => b.score - a.score)
    .slice(0, maxSuggestions)
    .map(({ item }) => item);
};

export const searchItems = (
  items: Item[],
  searchTerm: string,
  filters: FilterOptions
): Item[] => {
  let results = items;

  // Apply search term
  if (searchTerm) {
    results = items
      .map(item => ({
        item,
        score: calculateRelevanceScore(item, searchTerm)
      }))
      .filter(({ score }) => score > 0)
      .sort((a, b) => b.score - a.score)
      .map(({ item }) => item);
  }

  // Apply filters
  return filterItems(results, filters);
};