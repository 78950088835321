import React, { useState, useEffect } from 'react';
import { X, Search, ArrowLeft } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../lib/firebase';
import { collection, query, where, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
import ChatModal from './ChatModal';

interface Chat {
  id: string;
  chatId: string;
  itemId: string;
  itemName: string;
  participants: string[];
  lastMessage: string;
  lastMessageAt: any;
  unreadBy: Record<string, number>;
}

interface MessagesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MessagesModal({ isOpen, onClose }: MessagesModalProps) {
  const [chats, setChats] = useState<Chat[]>([]);
  const [filteredChats, setFilteredChats] = useState<Chat[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const { user } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!isOpen || !user) return;

    const fetchChats = async () => {
      try {
        const chatsRef = collection(db, 'chats');
        const baseQuery = query(
          chatsRef,
          where('participants', 'array-contains', user.uid)
        );

        const snapshot = await getDocs(baseQuery);
        const chatDocs = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Chat[];

        const sortedChats = chatDocs.sort((a, b) => {
          const timeA = a.lastMessageAt?.toMillis?.() || 0;
          const timeB = b.lastMessageAt?.toMillis?.() || 0;
          return timeB - timeA;
        });

        setChats(sortedChats);
        setFilteredChats(sortedChats);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };

    fetchChats();

    const chatsRef = collection(db, 'chats');
    const baseQuery = query(
      chatsRef,
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(baseQuery, (snapshot) => {
      const updatedChats = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Chat[];

      const sortedChats = updatedChats.sort((a, b) => {
        const timeA = a.lastMessageAt?.toMillis?.() || 0;
        const timeB = b.lastMessageAt?.toMillis?.() || 0;
        return timeB - timeA;
      });

      setChats(sortedChats);
      setFilteredChats(sortedChats);
    });

    return () => unsubscribe();
  }, [isOpen, user]);

  useEffect(() => {
    const filtered = chats.filter(chat => 
      chat.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      chat.lastMessage.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChats(filtered);
  }, [searchTerm, chats]);

  if (!isOpen || !user) return null;

  const renderChatsList = () => (
    <div className="w-full md:w-80 border-r">
      <div className="p-3 border-b">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search messages..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>
      <div className="overflow-y-auto h-[calc(100%-64px)]">
        {filteredChats.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            {searchTerm ? 'No messages found' : 'No messages yet'}
          </div>
        ) : (
          filteredChats.map((chat) => (
            <button
              key={chat.id}
              onClick={() => setSelectedChat(chat)}
              className={`w-full p-4 text-left hover:bg-gray-50 transition-colors border-b ${
                selectedChat?.id === chat.id ? 'bg-indigo-50' : ''
              } ${chat.unreadBy?.[user.uid] ? 'bg-indigo-50/50' : ''}`}
            >
              <div className="flex justify-between items-start mb-1">
                <h4 className="font-medium text-gray-900 line-clamp-1">{chat.itemName}</h4>
                <span className="text-xs text-gray-500 whitespace-nowrap ml-2">
                  {chat.lastMessageAt?.toDate?.().toLocaleDateString()}
                </span>
              </div>
              <p className="text-sm text-gray-600 line-clamp-1">
                {chat.lastMessage || 'No messages yet'}
              </p>
              {chat.unreadBy?.[user.uid] > 0 && (
                <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-indigo-600 rounded-full mt-2">
                  {chat.unreadBy[user.uid]}
                </span>
              )}
            </button>
          ))
        )}
      </div>
    </div>
  );

  const renderChatArea = () => (
    <div className="flex-1 flex items-center justify-center bg-gray-50">
      {selectedChat ? (
        <ChatModal
          isOpen={true}
          onClose={() => setSelectedChat(null)}
          itemId={selectedChat.itemId}
          sellerId={selectedChat.participants.find(p => p !== user.uid) || ''}
          itemName={selectedChat.itemName}
          embedded={true}
          chatId={selectedChat.chatId}
        />
      ) : (
        <div className="text-center text-gray-500">
          <p>Select a conversation to start messaging</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl h-[90vh] flex flex-col">
        {/* Header */}
        <div className="p-4 border-b flex items-center gap-4">
          {isMobile && selectedChat && (
            <button
              onClick={() => setSelectedChat(null)}
              className="p-1 rounded-full hover:bg-gray-100"
            >
              <ArrowLeft className="w-6 h-6" />
            </button>
          )}
          <h3 className="text-lg font-semibold flex-1">
            {isMobile && selectedChat ? selectedChat.itemName : 'Messages'}
          </h3>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 flex overflow-hidden">
          {(!isMobile || !selectedChat) && renderChatsList()}
          {(!isMobile || selectedChat) && renderChatArea()}
        </div>
      </div>
    </div>
  );
}