import { z } from 'zod';

export const locationSchema = z.object({
  city: z.string().min(1, 'City is required'),
  state: z.string().min(1, 'State is required')
});

export const deliverySchema = z.object({
  option: z.enum(['pickup', 'delivery', 'both']),
  fee: z.number().min(0, 'Delivery fee must be 0 or greater').optional()
});

export const itemSchema = z.object({
  name: z.string().min(3, 'Item name must be at least 3 characters'),
  description: z.string().min(10, 'Description must be at least 10 characters'),
  category: z.string().min(1, 'Please select a category'),
  condition: z.string().min(1, 'Please select a condition'),
  price: z.number().min(0, 'Price must be 0 or greater'),
  community: z.string().optional(),
  contactPreference: z.enum(['message', 'phone', 'email', 'both']),
  phoneNumber: z.string().optional(),
  email: z.string().email('Invalid email address').optional(),
  images: z.array(z.string()).min(1, 'At least one image is required'),
  location: locationSchema,
  delivery: deliverySchema
}).refine((data) => {
  if (data.contactPreference === 'phone' || data.contactPreference === 'both') {
    return !!data.phoneNumber;
  }
  return true;
}, {
  message: "Phone number is required when contact preference includes phone",
  path: ["phoneNumber"],
}).refine((data) => {
  if (data.contactPreference === 'email') {
    return !!data.email;
  }
  return true;
}, {
  message: "Email is required when contact preference is email",
  path: ["email"],
});

export type ItemFormData = z.infer<typeof itemSchema>;