import React, { useState, useCallback, useEffect } from 'react';
import { Heart, MapPin, Clock, Store, Truck } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useFavorites } from '../hooks/useFavorites';
import ChatModal from './ChatModal';
import ProductModal from './ProductModal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { formatDistanceToNow } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface ProductProps {
  id: string;
  name: string;
  images: string[];
  description: string;
  price: number;
  uniqueKey?: string;
  userId: string;
  contactPreference?: 'message' | 'phone' | 'email' | 'both';
  phoneNumber?: string;
  email?: string;
  createdAt: Date;
  delivery?: {
    option: 'pickup' | 'delivery' | 'both';
    fee?: number;
  };
  location?: {
    city: string;
    state: string;
  };
}

export default function ProductCard({ 
  id, 
  name, 
  images = [], 
  price = 0,
  uniqueKey, 
  userId,
  createdAt,
  location,
  delivery,
  contactPreference = 'message',
  phoneNumber,
  email,
  description
}: ProductProps) {
  const { user } = useAuth();
  const { favorites, toggleFavorite } = useFavorites();
  const navigate = useNavigate();
  
  const [showChatModal, setShowChatModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDealer, setIsDealer] = useState<boolean | null>(null);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  
  const isFavorite = favorites.includes(id);
  const isOwnItem = user?.uid === userId;

  useEffect(() => {
    const checkSellerType = async () => {
      try {
        const sellerDoc = await getDoc(doc(db, 'users', userId));
        if (sellerDoc.exists()) {
          setIsDealer(sellerDoc.data().userType === 'dealer');
        }
      } catch (error) {
        console.error('Error checking seller type:', error);
      }
    };

    checkSellerType();
  }, [userId]);

  const defaultImage = 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?w=800&auto=format&fit=crop&q=60';
  const allImages = images.length > 0 ? images : [defaultImage];
  const timeAgo = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
    } else if (isRightSwipe) {
      setCurrentImageIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user) {
      toast.error('Please sign in to save favorites');
      return;
    }
    await toggleFavorite(id);
  };

  const nextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
  };

  const previousImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
  };

  return (
    <>
      <div 
        className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden cursor-pointer relative flex flex-col h-full"
        onClick={() => setShowProductModal(true)}
      >
        <div className="relative group">
          <div 
            className="relative h-48 sm:h-64 w-full"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img 
              src={allImages[currentImageIndex]} 
              alt={name} 
              className="h-full w-full object-cover"
            />
            {allImages.length > 1 && (
              <>
                <button 
                  onClick={previousImage}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hidden sm:block"
                >
                  ←
                </button>
                <button 
                  onClick={nextImage}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity hidden sm:block"
                >
                  →
                </button>
                <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
                  {allImages.map((_, index) => (
                    <div 
                      key={index}
                      className={`w-2 h-2 rounded-full ${
                        index === currentImageIndex ? 'bg-white' : 'bg-white/50'
                      }`}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          <button 
            onClick={handleFavoriteClick}
            className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors"
          >
            <Heart 
              className={`w-5 h-5 ${isFavorite ? 'fill-red-500 text-red-500' : 'text-gray-600'}`} 
            />
          </button>
        </div>

        <div className="p-4 flex flex-col flex-grow">
          <div className="flex justify-between items-start gap-2">
            <h3 className="text-lg font-semibold text-gray-800 line-clamp-1">{name}</h3>
            <span className="text-sm bg-green-100 text-green-800 px-2 py-1 rounded-full whitespace-nowrap">
              {price === 0 ? 'Free' : `$${price.toFixed(2)}`}
            </span>
          </div>

          <div className="mt-auto pt-4 space-y-2 text-sm text-gray-500">
            {location && (
              <div className="flex items-center gap-2">
                <MapPin className="w-4 h-4 flex-shrink-0" />
                <span className="line-clamp-1">{`${location.city}, ${location.state}`}</span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4 flex-shrink-0" />
              <span>{timeAgo}</span>
            </div>
          </div>
        </div>
      </div>

      {showChatModal && contactPreference !== 'email' && (
        <ChatModal
          isOpen={showChatModal}
          onClose={() => setShowChatModal(false)}
          itemId={id}
          sellerId={userId}
          itemName={name}
        />
      )}

      <ProductModal
        isOpen={showProductModal}
        onClose={() => setShowProductModal(false)}
        onMessageClick={() => {
          setShowProductModal(false);
          setShowChatModal(true);
        }}
        item={{
          id,
          name,
          images: allImages,
          description,
          price,
          userId,
          createdAt,
          location,
          contactPreference,
          phoneNumber,
          email,
          isDealer,
          delivery
        }}
      />
    </>
  );
}