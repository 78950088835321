import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MessageSquare, User, Menu, X } from 'lucide-react';
import AuthButton from './AuthButton';
import { useAuth } from '../hooks/useAuth';
import MessagesModal from './MessagesModal';
import SearchBar from './SearchBar';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export default function Navbar() {
  const { user, userType } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showMessages, setShowMessages] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      setUnreadCount(0);
      return;
    }

    const chatsRef = collection(db, 'chats');
    const chatsQuery = query(
      chatsRef,
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const totalUnread = snapshot.docs.reduce((acc, doc) => {
        const data = doc.data();
        return acc + (data.unreadBy?.[user.uid] || 0);
      }, 0);
      setUnreadCount(totalUnread);
    });

    return () => unsubscribe();
  }, [user]);

  const handleSearch = (term: string) => {
    if (term.trim()) {
      navigate(`/?search=${encodeURIComponent(term.trim())}`);
      setIsSearchOpen(false);
    }
  };

  const handleProfileClick = () => {
    if (userType === 'dealer') {
      navigate(`/dealer-profile/${user?.uid}`);
    } else {
      navigate('/profile');
    }
    setIsMenuOpen(false);
  };

  const handleLogoClick = () => {
    navigate('/', { replace: true });
    setIsMenuOpen(false);
    setIsSearchOpen(false);
  };

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMenuOpen(false);
    setIsSearchOpen(false);
  }, [location.pathname]);

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          {/* Mobile menu button */}
          <div className="flex items-center lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-3 -ml-3 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
              aria-label="Menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Logo */}
          <button
            onClick={handleLogoClick}
            className="text-xl font-bold text-indigo-600 flex-shrink-0 hover:text-indigo-700 transition-colors"
          >
            HereDeal
          </button>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-4">
            {user && (
              <>
                <button
                  onClick={handleProfileClick}
                  className={`p-2 rounded-full transition-colors ${
                    (location.pathname === '/profile' || location.pathname.includes('/dealer-profile/'))
                      ? 'bg-indigo-50 text-indigo-600'
                      : 'hover:bg-gray-100 text-gray-600'
                  }`}
                  aria-label="Profile"
                >
                  <User className="h-6 w-6" />
                </button>
                <button 
                  onClick={() => setShowMessages(true)}
                  className="p-2 hover:bg-gray-100 rounded-full relative"
                  aria-label="Messages"
                >
                  <MessageSquare className="h-6 w-6 text-gray-600" />
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-indigo-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {unreadCount > 99 ? '99+' : unreadCount}
                    </span>
                  )}
                </button>
              </>
            )}
            <AuthButton />
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {isMenuOpen && (
          <div className="lg:hidden border-t border-gray-200">
            <div className="py-2">
              {user && (
                <>
                  <button
                    onClick={handleProfileClick}
                    className={`w-full text-left px-4 py-3 rounded-lg ${
                      (location.pathname === '/profile' || location.pathname.includes('/dealer-profile/'))
                        ? 'bg-indigo-50 text-indigo-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <User className="h-5 w-5" />
                      <span>Profile</span>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      setShowMessages(true);
                      setIsMenuOpen(false);
                    }}
                    className="w-full text-left px-4 py-3 text-gray-600 hover:bg-gray-50 rounded-lg"
                  >
                    <div className="flex items-center gap-3">
                      <MessageSquare className="h-5 w-5" />
                      <span>Messages</span>
                      {unreadCount > 0 && (
                        <span className="bg-indigo-600 text-white text-xs rounded-full h-5 px-2 flex items-center justify-center ml-auto">
                          {unreadCount > 99 ? '99+' : unreadCount}
                        </span>
                      )}
                    </div>
                  </button>
                </>
              )}
              <div className="px-4 py-2">
                <AuthButton />
              </div>
            </div>
          </div>
        )}
      </div>

      <MessagesModal 
        isOpen={showMessages} 
        onClose={() => setShowMessages(false)} 
      />
    </nav>
  );
}