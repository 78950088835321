import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './useAuth';
import toast from 'react-hot-toast';

export interface Rating {
  id: string;
  rating: number;
  comment: string;
  userId: string;
  dealerId: string;
  createdAt: any;
  userName: string;
}

export function useDealerRatings(dealerId: string) {
  const [ratings, setRatings] = useState<Rating[]>([]);
  const [loading, setLoading] = useState(true);
  const [averageRating, setAverageRating] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    if (!dealerId) {
      setLoading(false);
      return;
    }

    let unsubscribe: () => void;

    try {
      const ratingsRef = collection(db, 'ratings');
      const ratingsQuery = query(
        ratingsRef,
        where('dealerId', '==', dealerId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(ratingsQuery, (snapshot) => {
        const fetchedRatings = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Rating[];

        setRatings(fetchedRatings);

        if (fetchedRatings.length > 0) {
          const total = fetchedRatings.reduce((sum, rating) => sum + rating.rating, 0);
          setAverageRating(total / fetchedRatings.length);
        }

        setLoading(false);
      });
    } catch (error) {
      console.error('Error setting up ratings listener:', error);
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dealerId]);

  const addRating = async (rating: number, comment: string) => {
    if (!user) {
      throw new Error('Must be signed in to rate');
    }

    if (!dealerId) {
      throw new Error('Invalid dealer ID');
    }

    if (user.uid === dealerId) {
      throw new Error('You cannot rate your own profile');
    }

    try {
      // Check if user has already rated this dealer
      const existingRatingsQuery = query(
        collection(db, 'ratings'),
        where('userId', '==', user.uid),
        where('dealerId', '==', dealerId)
      );

      const existingRatings = await getDocs(existingRatingsQuery);
      if (!existingRatings.empty) {
        throw new Error('You have already rated this dealer');
      }

      const ratingData = {
        rating,
        comment: comment.trim(),
        userId: user.uid,
        userName: user.displayName || 'Anonymous',
        dealerId,
        createdAt: serverTimestamp()
      };

      await addDoc(collection(db, 'ratings'), ratingData);
    } catch (error: any) {
      console.error('Error adding rating:', error);
      throw new Error(error.message || 'Failed to submit rating');
    }
  };

  return {
    ratings,
    loading,
    averageRating,
    addRating
  };
}