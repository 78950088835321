import React, { useCallback } from 'react';
import { X, MessageSquarePlus, MapPin, Phone, Clock, Store, Mail, Truck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { formatDistanceToNow } from 'date-fns';
import toast from 'react-hot-toast';

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onMessageClick: () => void;
  item: {
    id: string;
    name: string;
    images: string[];
    description: string;
    price: number;
    userId: string;
    createdAt: Date;
    contactPreference?: 'message' | 'phone' | 'email' | 'both';
    phoneNumber?: string;
    email?: string;
    isDealer?: boolean;
    delivery?: {
      option: 'pickup' | 'delivery' | 'both';
      fee?: number;
    };
    location?: {
      city: string;
      state: string;
    };
  };
}

export default function ProductModal({ isOpen, onClose, onMessageClick, item }: ProductModalProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const timeAgo = formatDistanceToNow(new Date(item.createdAt), { addSuffix: true });
  const isOwnItem = user?.uid === item.userId;

  const handleMessageClick = useCallback(() => {
    if (!user && item.contactPreference === 'message') {
      toast.error('Please sign in to message the seller');
      return;
    }
    onMessageClick();
  }, [user, onMessageClick, item.contactPreference]);

  const handleDealerPageClick = useCallback(() => {
    navigate(`/dealer-profile/${item.userId}`);
  }, [navigate, item.userId]);

  const handlePhoneCall = useCallback(() => {
    if (!user) {
      toast.error('Please sign in to see phone contact');
      return;
    }
    if (item.phoneNumber) {
      window.location.href = `tel:${item.phoneNumber}`;
    }
  }, [user, item.phoneNumber]);

  const handleEmailClick = useCallback(() => {
    if (item.email) {
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${item.email}&subject=Regarding: ${item.name}`, '_blank');
    }
  }, [item.email, item.name]);

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev + 1) % item.images.length);
  }, [item.images.length]);

  const previousImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev - 1 + item.images.length) % item.images.length);
  }, [item.images.length]);

  const renderDeliveryBadge = () => {
    if (!item.delivery) return null;

    const { option, fee } = item.delivery;
    let badgeText = '';
    let badgeColor = '';

    switch (option) {
      case 'pickup':
        badgeText = 'Pickup Only';
        badgeColor = 'bg-yellow-100 text-yellow-800';
        break;
      case 'delivery':
        badgeText = fee ? `Delivery: $${fee}` : 'Free Delivery';
        badgeColor = 'bg-green-100 text-green-800';
        break;
      case 'both':
        badgeText = fee ? `Pickup & Delivery: $${fee}` : 'Pickup & Free Delivery';
        badgeColor = 'bg-blue-100 text-blue-800';
        break;
    }

    return (
      <div className={`flex items-center gap-2 ${badgeColor} px-3 py-1 rounded-full text-sm`}>
        <Truck className="w-4 h-4" />
        <span>{badgeText}</span>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4" onClick={onClose}>
      <div 
        className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col md:flex-row"
        onClick={e => e.stopPropagation()}
      >
        {/* Image Gallery */}
        <div className="relative w-full md:w-1/2 h-64 md:h-auto">
          <img
            src={item.images[currentImageIndex]}
            alt={item.name}
            className="w-full h-full object-cover"
          />
          {item.images.length > 1 && (
            <>
              <button
                onClick={previousImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
              >
                ←
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full"
              >
                →
              </button>
              <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
                {item.images.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${
                      index === currentImageIndex ? 'bg-white' : 'bg-white/50'
                    }`}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        {/* Content */}
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-xl md:text-2xl font-bold text-gray-900">{item.name}</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="flex flex-wrap gap-2 mb-6">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
              {item.price === 0 ? 'Free' : `$${item.price.toFixed(2)}`}
            </span>
            {renderDeliveryBadge()}
          </div>

          <div className="prose prose-sm max-w-none mb-6">
            <p className="text-gray-600 whitespace-pre-wrap">{item.description}</p>
          </div>

          <div className="space-y-3 mb-6">
            {item.location && (
              <div className="flex items-center gap-2 text-gray-600">
                <MapPin className="w-5 h-5 flex-shrink-0" />
                <span>{`${item.location.city}, ${item.location.state}`}</span>
              </div>
            )}
            <div className="flex items-center gap-2 text-gray-600">
              <Clock className="w-5 h-5 flex-shrink-0" />
              <span>{timeAgo}</span>
            </div>
          </div>

          {!isOwnItem && (
            <div className="flex flex-col gap-3">
              {item.isDealer && (
                <button
                  onClick={handleDealerPageClick}
                  className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <Store className="w-5 h-5" />
                  Visit Dealer Profile
                </button>
              )}
              
              {(item.contactPreference === 'message' || item.contactPreference === 'both') && (
                <button
                  onClick={handleMessageClick}
                  className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                  <MessageSquarePlus className="w-5 h-5" />
                  Send Message
                </button>
              )}
              
              {(item.contactPreference === 'phone' || item.contactPreference === 'both') && item.phoneNumber && (
                <button
                  onClick={handlePhoneCall}
                  className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                  <Phone className="w-5 h-5" />
                  Call
                </button>
              )}

              {(item.contactPreference === 'email' || item.email) && (
                <button
                  onClick={handleEmailClick}
                  className="flex items-center justify-center gap-2 w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Mail className="w-5 h-5" />
                  Email
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}