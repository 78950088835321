import React, { useRef, useState, useEffect } from 'react';
import { Image as ImageIcon, X } from 'lucide-react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ImageUploadProps {
  isSubmitting: boolean;
  onImagesUploaded: (urls: string[]) => void;
  defaultImages?: string[];
  maxImages?: number;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

const ImageUpload: React.FC<ImageUploadProps> = ({
  isSubmitting,
  onImagesUploaded,
  defaultImages = [],
  maxImages = 5,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<string[]>(defaultImages);
  const [uploading, setUploading] = useState(false);

  // Validate file
  const validateFile = (file: File): boolean => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error(`${file.name} exceeds the size limit of 5MB`);
      return false;
    }
    if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
      toast.error(`${file.name} is not a supported format`);
      return false;
    }
    return true;
  };

  // Upload a single image
  const uploadImage = async (file: File): Promise<string> => {
    if (!auth.currentUser) {
      throw new Error('User must be signed in to upload images.');
    }

    const timestamp = Date.now();
    const fileName = `${timestamp}_${file.name}`;
    const storageRef = ref(storage, `items/${auth.currentUser.uid}/${fileName}`);
    const snapshot = await uploadBytes(storageRef, file);
    return getDownloadURL(snapshot.ref);
  };

  // Handle image selection and upload
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (previews.length + files.length > maxImages) {
      toast.error(`You can only upload up to ${maxImages} images`);
      return;
    }

    const validFiles = files.filter(validateFile);
    if (validFiles.length === 0) return;

    // Temporary previews
    const newPreviews = validFiles.map((file) => URL.createObjectURL(file));
    setPreviews((prev) => [...prev, ...newPreviews]);

    try {
      setUploading(true);
      const uploadPromises = validFiles.map((file) => uploadImage(file));
      const uploadedUrls = await Promise.all(uploadPromises);

      onImagesUploaded(uploadedUrls);
      toast.success('Images uploaded successfully!');
    } catch (error) {
      console.error('Image upload failed:', error);
      toast.error('Error uploading images. Please try again.');
      // Remove failed previews
      setPreviews((prev) => prev.filter((preview) => !newPreviews.includes(preview)));
    } finally {
      setUploading(false);
      fileInputRef.current && (fileInputRef.current.value = '');
    }
  };

  // Remove image
  const removeImage = (index: number) => {
    const removedPreview = previews[index];
    if (!defaultImages.includes(removedPreview)) {
      URL.revokeObjectURL(removedPreview);
    }
    setPreviews((prev) => prev.filter((_, i) => i !== index));
    onImagesUploaded(previews.filter((_, i) => i !== index));
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      previews.forEach((preview) => {
        if (!defaultImages.includes(preview)) {
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [previews, defaultImages]);

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Upload Images (Max: {maxImages})
      </label>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
        {previews.map((preview, index) => (
          <div key={preview} className="relative group aspect-square">
            <img
              src={preview}
              alt={`Preview ${index + 1}`}
              className="w-full h-full object-cover rounded-lg"
            />
            <button
              type="button"
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100"
              onClick={() => removeImage(index)}
              disabled={isSubmitting || uploading}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
        {previews.length < maxImages && (
          <div
            onClick={() => fileInputRef.current?.click()}
            className="aspect-square border-2 border-dashed rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer hover:border-indigo-500"
          >
            <ImageIcon className="w-8 h-8 text-gray-400 mb-2" />
            <p className="text-sm text-gray-500 text-center">Add Images</p>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleImageChange}
        multiple
        accept={ACCEPTED_IMAGE_TYPES.join(',')}
        disabled={uploading || isSubmitting}
      />
      {uploading && (
        <div className="flex items-center gap-2 text-gray-600 text-sm">
          <div className="w-4 h-4 border-2 border-indigo-600 border-t-transparent rounded-full animate-spin"></div>
          Uploading...
        </div>
      )}
      <p className="text-xs text-gray-500 mt-2">Supported: JPG, PNG, WebP (Max: 5MB)</p>
    </div>
  );
};

export default ImageUpload;