import React, { useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import SearchBar from '../components/Search/SearchBar';
import SearchFilters from '../components/Search/SearchFilters';
import { useItems } from '../hooks/useItems';
import { AlertCircle, Filter, ChevronLeft, X } from 'lucide-react';
import { getCurrentLocation } from '../utils/location';
import { searchItems } from '../utils/search';
import { FilterOptions } from '../types/filters';
import toast from 'react-hot-toast';

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);
  const [isFiltersPanelOpen, setIsFiltersPanelOpen] = useState(true);
  const [filters, setFilters] = useState<FilterOptions>({
    sortBy: 'newest',
    sellerType: 'all',
    condition: 'all'
  });
  const { items, loading, error } = useItems();

  const handleLocationFilter = async () => {
    try {
      const location = await getCurrentLocation();
      if (location) {
        setFilters(prev => ({
          ...prev,
          location: {
            ...location,
            nearMe: true
          }
        }));
        toast.success(`Showing items in ${location.city}, ${location.state}`);
      }
    } catch (error) {
      console.error('Error getting location:', error);
      toast.error('Failed to get your location');
    }
  };

  const handleSearch = useCallback((term: string) => {
    if (term) {
      setSearchParams({ search: term });
    } else {
      setSearchParams({});
    }
  }, [setSearchParams]);

  const filteredItems = React.useMemo(() => {
    const searchTerm = searchParams.get('search') || '';
    return searchItems(items, searchTerm, filters);
  }, [items, searchParams, filters]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <main className="min-h-screen bg-gray-50">
      {/* Mobile Filter Toggle */}
      <div className="lg:hidden p-4">
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 shadow-sm"
        >
          <Filter className="w-5 h-5" />
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>
      </div>

      <div className="flex max-w-[1920px] mx-auto">
        {/* Desktop Filters Panel */}
        <aside 
          className={`
            hidden lg:block h-[calc(100vh-4rem)] sticky top-16
            transition-all duration-300 ease-in-out
            border-r border-gray-200 bg-white
            ${isFiltersPanelOpen ? 'w-80' : 'w-16'}
          `}
        >
          <div className="h-full overflow-y-auto">
            <div className="p-4 relative">
              {/* Toggle Button */}
              <button
                onClick={() => setIsFiltersPanelOpen(!isFiltersPanelOpen)}
                className="absolute -right-3 top-4 bg-white border border-gray-200 rounded-full p-1.5 shadow-sm hover:bg-gray-50"
                aria-label={isFiltersPanelOpen ? 'Collapse filters' : 'Expand filters'}
              >
                <ChevronLeft 
                  className={`w-4 h-4 transition-transform duration-300 ${
                    isFiltersPanelOpen ? '' : 'rotate-180'
                  }`}
                />
              </button>

              {/* Filter Content */}
              <div 
                className={`
                  transition-all duration-300 ease-in-out
                  ${isFiltersPanelOpen ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-4 pointer-events-none'}
                `}
              >
                <SearchBar
                  items={items}
                  onSearch={handleSearch}
                  className="mb-4"
                  placeholder="Search by name, description, or dealer..."
                  initialTerm={searchParams.get('search') || ''}
                />

                <SearchFilters
                  filters={filters}
                  onFilterChange={setFilters}
                  onGetLocation={handleLocationFilter}
                />
              </div>
            </div>
          </div>
        </aside>

        {/* Mobile Filters */}
        {showFilters && (
          <div className="lg:hidden fixed inset-0 z-40">
            <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setShowFilters(false)} />
            <div className="absolute right-0 h-full w-full max-w-sm bg-white transform transition-transform duration-300 ease-in-out">
              <div className="h-full overflow-y-auto p-4">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">Filters</h2>
                  <button
                    onClick={() => setShowFilters(false)}
                    className="p-2 hover:bg-gray-100 rounded-lg"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>

                <SearchBar
                  items={items}
                  onSearch={handleSearch}
                  className="mb-4"
                  placeholder="Search by name, description, or dealer..."
                  initialTerm={searchParams.get('search') || ''}
                />

                <SearchFilters
                  filters={filters}
                  onFilterChange={setFilters}
                  onGetLocation={handleLocationFilter}
                />
              </div>
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="flex-1 p-4 lg:p-8">
          {error && (
            <div className="mb-6 bg-red-50 border border-red-200 rounded-lg p-4 flex items-center gap-2 text-red-700">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          {filteredItems.length === 0 ? (
            <div className="bg-white rounded-lg shadow-sm p-6 sm:p-8 text-center">
              <p className="text-gray-500 text-lg">No items found. Try adjusting your filters.</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
              {filteredItems.map((item) => (
                <ProductCard 
                  key={item.uniqueKey || `product-${item.id}-${Date.now()}`} 
                  {...item} 
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </main>
  );
}