import React from 'react';
import { useForm } from 'react-hook-form';
import { X, Store } from 'lucide-react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

const dealerProfileSchema = z.object({
  displayName: z.string().min(3, 'Display name must be at least 3 characters'),
  dealerName: z.string().min(3, 'Dealer name must be at least 3 characters'),
  location: z.object({
    city: z.string().min(1, 'City is required'),
    state: z.string().min(1, 'State is required'),
    zipCode: z.string().min(1, 'ZIP code is required')
  }),
  phoneNumber: z.string().min(1, 'Phone number is required'),
  bio: z.string().min(10, 'Business description must be at least 10 characters'),
  email: z.string().email('Invalid email address')
});

type DealerProfileFormData = z.infer<typeof dealerProfileSchema>;

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  userId: string;
}

export default function SignUpModal({ isOpen, onClose, onComplete, userId }: SignUpModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DealerProfileFormData>({
    resolver: zodResolver(dealerProfileSchema)
  });

  const onSubmit = async (data: DealerProfileFormData) => {
    try {
      await setDoc(doc(db, 'users', userId), {
        ...data,
        userType: 'dealer',
        createdAt: Date.now(),
        joinedDate: Date.now(),
      }, { merge: true });
      
      toast.success('Dealer profile created successfully!');
      onComplete();
    } catch (error) {
      console.error('Error creating dealer profile:', error);
      toast.error('Failed to create profile');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="flex items-center gap-3 mb-6">
          <Store className="w-8 h-8 text-indigo-600" />
          <h2 className="text-2xl font-bold">Complete Dealer Profile</h2>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Display Name
            </label>
            <input
              {...register('displayName')}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Your name"
            />
            {errors.displayName && (
              <p className="text-red-500 text-sm mt-1">{errors.displayName.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Business Name
            </label>
            <input
              {...register('dealerName')}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Your business name"
            />
            {errors.dealerName && (
              <p className="text-red-500 text-sm mt-1">{errors.dealerName.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Business Email
            </label>
            <input
              {...register('email')}
              type="email"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="your@business.com"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone Number
            </label>
            <input
              {...register('phoneNumber')}
              type="tel"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Your phone number"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">{errors.phoneNumber.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <div className="grid grid-cols-2 gap-4">
              <input
                {...register('location.city')}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="City"
              />
              <input
                {...register('location.state')}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="State"
              />
            </div>
            <input
              {...register('location.zipCode')}
              className="w-full mt-4 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="ZIP Code"
            />
            {errors.location && (
              <p className="text-red-500 text-sm mt-1">Please complete all location fields</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Business Description
            </label>
            <textarea
              {...register('bio')}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              rows={4}
              placeholder="Tell us about your business..."
            />
            {errors.bio && (
              <p className="text-red-500 text-sm mt-1">{errors.bio.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Complete Profile
          </button>
        </form>
      </div>
    </div>
  );
}