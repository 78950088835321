import { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './useAuth';
import toast from 'react-hot-toast';

export function useFavorites() {
  const [favorites, setFavorites] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setFavorites([]);
      setLoading(false);
      return;
    }

    const fetchFavorites = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setFavorites(userDoc.data().favorites || []);
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
        toast.error('Failed to load favorites');
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [user]);

  const toggleFavorite = async (itemId: string) => {
    if (!user) {
      toast.error('Please sign in to save favorites');
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await setDoc(userRef, { favorites: [itemId] });
        setFavorites([itemId]);
      } else {
        const currentFavorites = userDoc.data().favorites || [];
        const isFavorite = currentFavorites.includes(itemId);

        if (isFavorite) {
          await updateDoc(userRef, {
            favorites: arrayRemove(itemId)
          });
          setFavorites(prev => prev.filter(id => id !== itemId));
        } else {
          await updateDoc(userRef, {
            favorites: arrayUnion(itemId)
          });
          setFavorites(prev => [...prev, itemId]);
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      toast.error('Failed to update favorites');
    }
  };

  return {
    favorites,
    loading,
    toggleFavorite
  };
}