import React from 'react';
import { MapPin } from 'lucide-react';
import { UseFormRegister, FormState } from 'react-hook-form';
import { ItemFormData } from '../../../utils/validation';
import { US_STATES } from '../../../utils/location';

interface LocationDetailsProps {
  register: UseFormRegister<ItemFormData>;
  errors: FormState<ItemFormData>['errors'];
  isSubmitting: boolean;
  onGetLocation: () => void;
  locationError: string | null;
}

export default function LocationDetails({
  register,
  errors,
  isSubmitting,
  onGetLocation,
  locationError
}: LocationDetailsProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Location
      </label>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <input
            {...register('location.city')}
            placeholder="City"
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            disabled={isSubmitting}
          />
          {errors.location?.city && (
            <p className="text-red-500 text-sm mt-1">{errors.location.city.message}</p>
          )}
        </div>
        <div>
          <select
            {...register('location.state')}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            disabled={isSubmitting}
          >
            <option value="">Select State</option>
            {US_STATES.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          {errors.location?.state && (
            <p className="text-red-500 text-sm mt-1">{errors.location.state.message}</p>
          )}
        </div>
      </div>
      <button
        type="button"
        onClick={onGetLocation}
        disabled={isSubmitting}
        className="mt-2 flex items-center gap-2 px-3 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors disabled:opacity-50"
      >
        <MapPin className="w-4 h-4" />
        Use Current Location
      </button>
      {locationError && (
        <p className="text-red-500 text-sm mt-1">{locationError}</p>
      )}
    </div>
  );
}