import React from 'react';
import { UseFormRegister, FormState, UseFormWatch } from 'react-hook-form';
import { ItemFormData } from '../../../utils/validation';
import { DELIVERY_OPTIONS } from '../../../utils/constants';

interface DeliveryOptionsProps {
  register: UseFormRegister<ItemFormData>;
  errors: FormState<ItemFormData>['errors'];
  watch: UseFormWatch<ItemFormData>;
  isSubmitting: boolean;
}

export default function DeliveryOptions({
  register,
  errors,
  watch,
  isSubmitting
}: DeliveryOptionsProps) {
  const deliveryOption = watch('delivery.option');

  return (
    <div className="space-y-4">
      <label className="block text-sm font-medium text-gray-700">
        Delivery Options
      </label>
      <select
        {...register('delivery.option')}
        disabled={isSubmitting}
        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
      >
        {DELIVERY_OPTIONS.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {(deliveryOption === 'delivery' || deliveryOption === 'both') && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Delivery Fee ($)
          </label>
          <input
            type="number"
            step="0.01"
            min="0"
            {...register('delivery.fee', { valueAsNumber: true })}
            disabled={isSubmitting}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
            placeholder="Enter delivery fee (0 for free delivery)"
          />
        </div>
      )}
    </div>
  );
}