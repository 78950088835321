import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA1v6X0pDZOD8jbbI-Acn1kIQ7Mpech1MQ",
  authDomain: "tradingx-56890.firebaseapp.com",
  databaseURL: "https://tradingx-56890-default-rtdb.firebaseio.com",
  projectId: "tradingx-56890",
  storageBucket: "tradingx-56890.firebasestorage.app",
  messagingSenderId: "45138590302",
  appId: "1:45138590302:web:fa287b2092b6f9c62cd2cf",
  measurementId: "G-YLVLDPLL8T"
};

// Initialize Firebase only if it hasn't been initialized yet
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// Initialize services
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Initialize analytics only if supported
const analytics = (async () => {
  try {
    if (await isSupported()) {
      return getAnalytics(app);
    }
    return null;
  } catch (error) {
    console.warn('Analytics not supported:', error);
    return null;
  }
})();

// Enable offline persistence
enableIndexedDbPersistence(db, { synchronizeTabs: true })
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time
      console.warn('Persistence disabled: multiple tabs open');
    } else if (err.code === 'unimplemented') {
      // The current browser doesn't support persistence
      console.warn('Persistence not supported by browser');
    }
  });

export { app, db, storage, auth, analytics };