import React from 'react';
import { MapPin } from 'lucide-react';
import { FilterOptions } from '../../types/filters';
import { FilterSection } from './FilterSection';
import { US_STATES, CATEGORIES, COMMUNITIES } from '../../utils/constants';

interface SearchFiltersProps {
  filters: FilterOptions;
  onFilterChange: (filters: FilterOptions) => void;
  onGetLocation: () => void;
}

export default function SearchFilters({ filters, onFilterChange, onGetLocation }: SearchFiltersProps) {
  const handleChange = (key: keyof FilterOptions, value: any) => {
    onFilterChange({ ...filters, [key]: value });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <FilterSection title="Category">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.category || 'all'}
          onChange={(e) => handleChange('category', e.target.value)}
        >
          <option value="all">All Categories</option>
          {CATEGORIES.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </FilterSection>

      <FilterSection title="Community">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.community || 'all'}
          onChange={(e) => handleChange('community', e.target.value)}
        >
          <option value="all">All Communities</option>
          {COMMUNITIES.map(community => (
            <option key={community} value={community}>{community}</option>
          ))}
        </select>
      </FilterSection>

      <FilterSection title="Price Range">
        <div className="flex gap-2">
          <input
            type="number"
            placeholder="Min"
            className="w-1/2 px-3 py-2 border rounded-lg"
            value={filters.priceRange?.min || ''}
            onChange={(e) => handleChange('priceRange', {
              ...filters.priceRange,
              min: Number(e.target.value)
            })}
          />
          <input
            type="number"
            placeholder="Max"
            className="w-1/2 px-3 py-2 border rounded-lg"
            value={filters.priceRange?.max || ''}
            onChange={(e) => handleChange('priceRange', {
              ...filters.priceRange,
              max: Number(e.target.value)
            })}
          />
        </div>
      </FilterSection>

      <FilterSection title="Location">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.location?.state || ''}
          onChange={(e) => handleChange('location', {
            ...filters.location,
            state: e.target.value
          })}
        >
          <option value="">All States</option>
          {US_STATES.map(state => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>
        <input
          type="text"
          placeholder="ZIP Code"
          className="w-full mt-2 px-3 py-2 border rounded-lg"
          value={filters.location?.zipCode || ''}
          onChange={(e) => handleChange('location', {
            ...filters.location,
            zipCode: e.target.value
          })}
        />
        <button
          onClick={onGetLocation}
          className="flex items-center gap-2 w-full mt-2 px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200"
        >
          <MapPin className="w-4 h-4" />
          Near Me
        </button>
      </FilterSection>

      <FilterSection title="Seller Type">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.sellerType || 'all'}
          onChange={(e) => handleChange('sellerType', e.target.value)}
        >
          <option value="all">All Sellers</option>
          <option value="dealer">Dealers</option>
          <option value="owner">Owners</option>
        </select>
      </FilterSection>

      <FilterSection title="Condition">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.condition || 'all'}
          onChange={(e) => handleChange('condition', e.target.value)}
        >
          <option value="all">All Conditions</option>
          <option value="new">New</option>
          <option value="like-new">Like New</option>
          <option value="good">Good</option>
          <option value="fair">Fair</option>
        </select>
      </FilterSection>

      <FilterSection title="Sort By">
        <select
          className="w-full px-3 py-2 border rounded-lg"
          value={filters.sortBy || 'newest'}
          onChange={(e) => handleChange('sortBy', e.target.value)}
        >
          <option value="newest">Newest First</option>
          <option value="price-low">Price: Low to High</option>
          <option value="price-high">Price: High to Low</option>
        </select>
      </FilterSection>
    </div>
  );
}