import React from 'react';
import { UseFormRegister, FormState } from 'react-hook-form';
import { ItemFormData } from '../../../utils/validation';
import { CATEGORIES, COMMUNITIES, CONDITIONS } from '../../../utils/constants';
import ImageUpload from '../../ImageUpload';

interface BasicDetailsProps {
  register: UseFormRegister<ItemFormData>;
  errors: FormState<ItemFormData>['errors'];
  isSubmitting: boolean;
  uploadedImages: string[];
  onImagesUploaded: (urls: string[]) => void;
}

export default function BasicDetails({
  register,
  errors,
  isSubmitting,
  uploadedImages,
  onImagesUploaded
}: BasicDetailsProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Item Name
        </label>
        <input
          {...register('name')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
          placeholder="Enter item name"
        />
        {errors.name && (
          <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
        )}
      </div>

      <ImageUpload
        isSubmitting={isSubmitting}
        onImagesUploaded={onImagesUploaded}
        defaultImages={uploadedImages}
        maxImages={5}
      />
      {errors.images && (
        <p className="text-red-500 text-sm mt-1">{errors.images?.message}</p>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Price ($)
        </label>
        <input
          type="number"
          step="0.01"
          min="0"
          {...register('price', { valueAsNumber: true })}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
          placeholder="Enter price (0 for free items)"
        />
        {errors.price && (
          <p className="text-red-500 text-sm mt-1">{errors.price.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category
        </label>
        <select
          {...register('category')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          <option value="">Select a category</option>
          {CATEGORIES.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        {errors.category && (
          <p className="text-red-500 text-sm mt-1">{errors.category.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Condition
        </label>
        <select
          {...register('condition')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          <option value="">Select condition</option>
          {CONDITIONS.map((condition) => (
            <option key={condition.value} value={condition.value}>
              {condition.label}
            </option>
          ))}
        </select>
        {errors.condition && (
          <p className="text-red-500 text-sm mt-1">{errors.condition.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Community (Optional)
        </label>
        <select
          {...register('community')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          <option value="">Select a community</option>
          {COMMUNITIES.map((community) => (
            <option key={community} value={community}>
              {community}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          {...register('description')}
          disabled={isSubmitting}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 disabled:opacity-50"
          rows={4}
          placeholder="Enter item description"
        />
        {errors.description && (
          <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
        )}
      </div>
    </div>
  );
}