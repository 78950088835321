import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { itemSchema } from '../../utils/validation';
import type { ItemFormData } from '../../utils/validation';
import BasicDetails from './FormSections/BasicDetails';
import DeliveryOptions from './FormSections/DeliveryOptions';
import ContactDetails from './FormSections/ContactDetails';
import LocationDetails from './FormSections/LocationDetails';

interface AddItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ItemFormData) => Promise<void>;
}

export default function AddItemModal({ isOpen, onClose, onSubmit }: AddItemModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ItemFormData>({
    resolver: zodResolver(itemSchema),
    defaultValues: {
      contactPreference: 'message',
      images: [],
      price: 0,
      location: {
        city: '',
        state: ''
      },
      delivery: {
        option: 'pickup',
        fee: 0
      }
    }
  });

  const handleImagesUploaded = (urls: string[]) => {
    setUploadedImages(urls);
    setValue('images', urls, { shouldValidate: true });
  };

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      setLocationError('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
          const data = await response.json();
          
          const city = data.address.city || data.address.town || data.address.village;
          const state = data.address.state;
          
          if (city && state) {
            setValue('location.city', city, { shouldValidate: true });
            setValue('location.state', state, { shouldValidate: true });
            setLocationError(null);
          } else {
            setLocationError('Could not determine your location');
          }
        } catch (error) {
          setLocationError('Failed to get location area');
        }
      },
      (error) => {
        setLocationError('Failed to get your location');
      }
    );
  };

  const onSubmitForm = async (data: ItemFormData) => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      await onSubmit(data);
      reset();
      setUploadedImages([]);
      onClose();
    } catch (error: any) {
      console.error('Error submitting form:', error);
      toast.error(error.message || 'Failed to add item');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    reset();
    setUploadedImages([]);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={handleClose}
          disabled={isSubmitting}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 disabled:opacity-50"
        >
          <X className="w-6 h-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">Add New Item</h2>

        <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
          <BasicDetails
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            uploadedImages={uploadedImages}
            onImagesUploaded={handleImagesUploaded}
          />

          <hr className="border-gray-200" />

          <DeliveryOptions
            register={register}
            errors={errors}
            watch={watch}
            isSubmitting={isSubmitting}
          />

          <hr className="border-gray-200" />

          <ContactDetails
            register={register}
            errors={errors}
            watch={watch}
            isSubmitting={isSubmitting}
          />

          <hr className="border-gray-200" />

          <LocationDetails
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            onGetLocation={getCurrentLocation}
            locationError={locationError}
          />

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
          >
            {isSubmitting ? (
              <>
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                Adding Item...
              </>
            ) : (
              'Add Item'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}