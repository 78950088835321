import React, { useState } from 'react';
import { Trash2, Tag, Edit, ChevronLeft, ChevronRight, MapPin, Clock } from 'lucide-react';
import EditItemModal from './EditItemModal';
import { Item } from '../../hooks/useItems';
import { formatDistanceToNow } from 'date-fns';

interface UserItemsProps {
  items: Item[];
  onDelete?: (id: string) => void;
  onUpdateStatus?: (id: string, status: 'available' | 'sold') => void;
  onUpdateItem?: (id: string, data: Partial<Item>) => void;
}

export default function UserItems({ items, onDelete, onUpdateStatus, onUpdateItem }: UserItemsProps) {
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
  const [editingItem, setEditingItem] = useState<Item | null>(null);
  const [currentImageIndexes, setCurrentImageIndexes] = useState<Record<string, number>>({});

  const handleDelete = (id: string) => {
    if (!onDelete) return;
    
    if (confirmDelete === id) {
      onDelete(id);
      setConfirmDelete(null);
    } else {
      setConfirmDelete(id);
      setTimeout(() => setConfirmDelete(null), 3000);
    }
  };

  const handleEdit = (item: Item) => {
    setEditingItem(item);
  };

  const handleUpdateItem = async (data: Partial<Item>) => {
    if (editingItem && onUpdateItem) {
      await onUpdateItem(editingItem.id, data);
      setEditingItem(null);
    }
  };

  const handleStatusUpdate = (id: string, status: 'available' | 'sold') => {
    if (onUpdateStatus) {
      onUpdateStatus(id, status);
    }
  };

  const nextImage = (itemId: string, imagesLength: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndexes(prev => ({
      ...prev,
      [itemId]: ((prev[itemId] || 0) + 1) % imagesLength
    }));
  };

  const previousImage = (itemId: string, imagesLength: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentImageIndexes(prev => ({
      ...prev,
      [itemId]: ((prev[itemId] || 0) - 1 + imagesLength) % imagesLength
    }));
  };

  // Default image if no images are provided
  const defaultImage = 'https://images.unsplash.com/photo-1472851294608-062f824d29cc?w=800&auto=format&fit=crop&q=60';

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {items.map((item) => {
          const allImages = item.images?.length > 0 ? item.images : [defaultImage];
          const currentIndex = currentImageIndexes[item.id] || 0;
          const timeAgo = formatDistanceToNow(new Date(item.createdAt), { addSuffix: true });
          const price = typeof item.price === 'number' ? item.price : 0;

          return (
            <div
              key={item.uniqueKey || `item-${item.id}`}
              className={`bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02] duration-300 ${
                item.status === 'sold' ? 'opacity-75' : ''
              }`}
            >
              <div className="relative group">
                <div className="relative h-64 w-full">
                  <img
                    src={allImages[currentIndex]}
                    alt={item.name}
                    className="h-full w-full object-cover"
                    loading="lazy"
                  />
                  {allImages.length > 1 && (
                    <>
                      <button
                        onClick={(e) => previousImage(item.id, allImages.length, e)}
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <ChevronLeft className="w-4 h-4" />
                      </button>
                      <button
                        onClick={(e) => nextImage(item.id, allImages.length, e)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <ChevronRight className="w-4 h-4" />
                      </button>
                      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-1">
                        {allImages.map((_, index) => (
                          <div
                            key={index}
                            className={`w-2 h-2 rounded-full ${
                              index === currentIndex ? 'bg-white' : 'bg-white/50'
                            }`}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="p-5">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-lg font-semibold text-gray-800">{item.name}</h3>
                  <div className="flex items-center gap-2">
                    <span className={`text-sm px-2 py-1 rounded-full ${
                      item.status === 'sold' 
                        ? 'bg-gray-100 text-gray-800'
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {item.status === 'sold' ? 'Sold' : (price === 0 ? 'Free' : `$${price.toFixed(2)}`)}
                    </span>
                  </div>
                </div>

                <p className="text-gray-600 text-sm mb-4 line-clamp-2">
                  {item.description}
                </p>

                <div className="flex flex-col gap-2 text-sm text-gray-500 mb-4">
                  {item.location?.area && (
                    <div className="flex items-center gap-2">
                      <MapPin className="w-4 h-4" />
                      <span className="line-clamp-1">{item.location.area}</span>
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    <span>{timeAgo}</span>
                  </div>
                </div>

                <div className="flex items-center justify-between mt-4 pt-4 border-t">
                  <span className="text-xs text-gray-500">ID: {item.id}</span>
                  <div className="flex gap-2">
                    {onUpdateItem && (
                      <button
                        onClick={() => handleEdit(item)}
                        className="p-2 text-gray-600 hover:text-indigo-600 transition-colors rounded-lg hover:bg-indigo-50"
                        title="Edit item"
                      >
                        <Edit className="w-5 h-5" />
                      </button>
                    )}
                    {onUpdateStatus && (
                      <button
                        onClick={() => handleStatusUpdate(item.id, item.status === 'sold' ? 'available' : 'sold')}
                        className={`p-2 transition-colors rounded-lg hover:bg-indigo-50 ${
                          item.status === 'sold'
                            ? 'text-gray-400 hover:text-indigo-600'
                            : 'text-gray-600 hover:text-indigo-600'
                        }`}
                        title={item.status === 'sold' ? 'Mark as Available' : 'Mark as Sold'}
                      >
                        <Tag className="w-5 h-5" />
                      </button>
                    )}
                    {onDelete && (
                      <button
                        onClick={() => handleDelete(item.id)}
                        className={`p-2 transition-colors rounded-lg ${
                          confirmDelete === item.id
                            ? 'text-red-600 bg-red-50'
                            : 'text-gray-600 hover:text-red-600 hover:bg-red-50'
                        }`}
                        title={confirmDelete === item.id ? 'Click again to confirm' : 'Delete item'}
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {editingItem && onUpdateItem && (
        <EditItemModal
          isOpen={true}
          onClose={() => setEditingItem(null)}
          onSubmit={handleUpdateItem}
          item={editingItem}
        />
      )}
    </>
  );
}