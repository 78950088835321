export const CATEGORIES = [
  'Electronics',
  'Accessories',
  'Gadgets',
  'Computers',
  'Phones',
  'Gaming',
  'Other',
];

export const COMMUNITIES = [
  'Middle East',
  'Latino',
  'Asian',
  'Europe',
  'African'
];

export const CONTACT_PREFERENCES = [
  { value: 'message', label: 'Message Only' },
  { value: 'phone', label: 'Phone Only' },
  { value: 'email', label: 'Email Only' },
  { value: 'both', label: 'Both Message and Phone' }
] as const;

export const DELIVERY_OPTIONS = [
  { value: 'pickup', label: 'Pickup Only' },
  { value: 'delivery', label: 'Delivery Only' },
  { value: 'both', label: 'Both Pickup & Delivery' }
] as const;

export const US_STATES = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 
  'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 
  'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 
  'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 
  'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 
  'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 
  'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 
  'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 
  'West Virginia', 'Wisconsin', 'Wyoming'
] as const;

export const CONDITIONS = [
  { value: 'new', label: 'New' },
  { value: 'like-new', label: 'Like New' },
  { value: 'good', label: 'Good' },
  { value: 'fair', label: 'Fair' }
] as const;

export const SORT_OPTIONS = [
  { value: 'newest', label: 'Newest First' },
  { value: 'price-low', label: 'Price: Low to High' },
  { value: 'price-high', label: 'Price: High to Low' }
] as const;