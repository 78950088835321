import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Profile from './pages/Profile';
import DealerProfile from './pages/DealerProfile';
import EmailVerificationBanner from './components/EmailVerificationBanner';
import { useAuth } from './hooks/useAuth';

export default function AppRoutes() {
  const { user, userType } = useAuth();

  return (
    <>
      <Navbar />
      <EmailVerificationBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dealer-profile/:dealerId" element={<DealerProfile />} />
      </Routes>
    </>
  );
}