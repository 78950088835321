import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useItems } from '../hooks/useItems';
import { useAuth } from '../hooks/useAuth';
import { useDealerRatings } from '../hooks/useDealerRatings';
import UserItems from '../components/UserProfile/UserItems';
import RatingModal from '../components/DealerProfile/RatingModal';
import ShareModal from '../components/DealerProfile/ShareModal';
import { User, MapPin, Mail, Phone, Edit, Plus, Star, Share2 } from 'lucide-react';
import AddItemModal from '../components/UserProfile/AddItemModal';
import EditDealerProfileModal from '../components/EditDealerProfileModal';
import toast from 'react-hot-toast';

interface Location {
  city?: string;
  state?: string;
  zipCode?: string;
}

interface DealerInfo {
  displayName: string;
  email: string;
  photoURL: string;
  bio?: string;
  location?: Location | string;
  phoneNumber?: string;
  joinedDate: number;
  userType: 'dealer' | 'owner';
}

interface ItemData {
  name: string;
  description: string;
  price: number;
  images?: string[];
  category?: string;
  community?: string;
  status?: string;
}

export default function DealerProfile() {
  const { dealerId } = useParams<{ dealerId: string }>();
  const [dealer, setDealer] = useState<DealerInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const { items, loading: itemsLoading, addItem, updateItem, deleteItem, updateItemStatus } = useItems(dealerId, true);
  const { user } = useAuth();
  const { averageRating, addRating } = useDealerRatings(dealerId || '');

  const isOwnProfile = user?.uid === dealerId;

  const formatLocation = (location: Location | string | undefined): string => {
    if (!location) return '';
    if (typeof location === 'string') return location;
    
    const { city, state, zipCode } = location;
    return [city, state, zipCode].filter(Boolean).join(', ');
  };

  useEffect(() => {
    const fetchDealerInfo = async () => {
      if (!dealerId) return;

      try {
        const dealerDoc = await getDoc(doc(db, 'users', dealerId));
        if (dealerDoc.exists()) {
          const data = dealerDoc.data() as DealerInfo;
          setDealer(data);
          
          if (data.userType !== 'dealer') {
            setDealer(null);
          }
        }
      } catch (error) {
        console.error('Error fetching dealer info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDealerInfo();
  }, [dealerId]);

  const handleProfileUpdate = async (updatedData: Partial<DealerInfo>) => {
    if (!dealerId) return;

    try {
      await updateDoc(doc(db, 'users', dealerId), updatedData);
      setDealer(prev => prev ? { ...prev, ...updatedData } : null);
      toast.success('Profile updated successfully');
      setShowEditProfile(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    }
  };

  const handleAddItem = async (data: ItemData) => {
    try {
      await addItem(data);
      setShowAddItem(false);
      toast.success('Item added successfully');
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item');
    }
  };

  const handleDeleteItem = async (id: string) => {
    try {
      await deleteItem(id);
      toast.success('Item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item');
    }
  };

  const handleUpdateItem = async (id: string, data: any) => {
    try {
      await updateItem(id, data);
      toast.success('Item updated successfully');
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Failed to update item');
    }
  };

  const handleUpdateStatus = async (id: string, status: 'available' | 'sold') => {
    try {
      await updateItemStatus(id, status);
      toast.success(`Item marked as ${status}`);
    } catch (error) {
      console.error('Error updating item status:', error);
      toast.error('Failed to update item status');
    }
  };

  const handleRatingSubmit = async (rating: number, comment: string) => {
    try {
      await addRating(rating, comment);
      toast.success('Rating submitted successfully');
      setShowRatingModal(false);
    } catch (error) {
      console.error('Error submitting rating:', error);
      toast.error('Failed to submit rating');
    }
  };

  if (loading || itemsLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!dealer) {
    return <Navigate to="/" replace />;
  }

  const locationString = formatLocation(dealer.location);

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Dealer Info Card */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
          <div className="p-6">
            <div className="flex items-start justify-between">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0">
                  {dealer.photoURL ? (
                    <img
                      src={dealer.photoURL}
                      alt={dealer.displayName}
                      className="w-24 h-24 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-24 h-24 rounded-full bg-indigo-100 flex items-center justify-center">
                      <User className="w-12 h-12 text-indigo-600" />
                    </div>
                  )}
                </div>
                
                <div className="flex-1">
                  <div className="flex items-center gap-4">
                    <h1 className="text-2xl font-bold text-gray-900">{dealer.displayName}</h1>
                    {averageRating > 0 && (
                      <div className="flex items-center gap-1 text-yellow-500">
                        <Star className="w-5 h-5 fill-current" />
                        <span className="font-medium">{averageRating.toFixed(1)}</span>
                      </div>
                    )}
                  </div>
                  
                  {dealer.bio && (
                    <p className="mt-2 text-gray-600">{dealer.bio}</p>
                  )}
                  
                  <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="flex items-center gap-2 text-gray-600">
                      <Mail className="w-5 h-5" />
                      <span>{dealer.email}</span>
                    </div>
                    
                    {dealer.phoneNumber && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <Phone className="w-5 h-5" />
                        <span>{dealer.phoneNumber}</span>
                      </div>
                    )}
                    
                    {locationString && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <MapPin className="w-5 h-5" />
                        <span>{locationString}</span>
                      </div>
                    )}
                    
                    <div className="text-sm text-gray-500">
                      Member since {new Date(dealer.joinedDate).toLocaleDateString()}
                    </div>
                  </div>

                  {!isOwnProfile && (
                    <div className="mt-6 flex gap-4">
                      <button
                        onClick={() => setShowRatingModal(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors"
                      >
                        <Star className="w-5 h-5" />
                        Rate Dealer
                      </button>
                      <button
                        onClick={() => setShowShareModal(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200 transition-colors"
                      >
                        <Share2 className="w-5 h-5" />
                        Share Profile
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {isOwnProfile && (
                <button
                  onClick={() => setShowEditProfile(true)}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-lg transition-colors"
                >
                  <Edit className="w-5 h-5" />
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Items Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-gray-900">Listed Items</h2>
          {isOwnProfile && (
            <button
              onClick={() => setShowAddItem(true)}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              <Plus className="w-5 h-5" />
              Add New Item
            </button>
          )}
        </div>

        {/* Dealer's Items */}
        {items.length === 0 ? (
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <p className="text-gray-600">No items listed yet.</p>
          </div>
        ) : (
          <UserItems
            items={items}
            onDelete={isOwnProfile ? handleDeleteItem : undefined}
            onUpdateStatus={isOwnProfile ? handleUpdateStatus : undefined}
            onUpdateItem={isOwnProfile ? handleUpdateItem : undefined}
          />
        )}
      </div>

      {/* Modals */}
      {showEditProfile && (
        <EditDealerProfileModal
          isOpen={showEditProfile}
          onClose={() => setShowEditProfile(false)}
          onSubmit={handleProfileUpdate}
          dealer={dealer}
        />
      )}

      {showAddItem && (
        <AddItemModal
          isOpen={showAddItem}
          onClose={() => setShowAddItem(false)}
          onSubmit={handleAddItem}
        />
      )}

      {showRatingModal && (
        <RatingModal
          isOpen={showRatingModal}
          onClose={() => setShowRatingModal(false)}
          onSubmit={handleRatingSubmit}
          dealerId={dealerId || ''}
        />
      )}

      {showShareModal && (
        <ShareModal
          isOpen={showShareModal}
          onClose={() => setShowShareModal(false)}
          dealerId={dealerId || ''}
          dealerName={dealer.displayName}
        />
      )}
    </div>
  );
}